import { useLayoutEffect, useState } from "react";

export const getImageByName = (productName: string): string => {
    switch (productName) {
      case "Tomato":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Pimen":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Olive":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Potato":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Octopus":
        return "./../../../assets/" + productName?.toString() + ".png";
  
      default:
        return "./../../../assets/Tomato.png";
    }
  };
  export function formatDateAndTime(inputDate: any) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
  
  export enum Unit {
    KG = "kilogramme",
    G = "gramme",
    L = "liter",
  }
  
  export enum orderPurchaseStatus {
    pending = "pending",
    completed = "completed",
    cancelled = "cancelled",
  }
  
  export function camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }
  export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  export function convertStatus(status: string) {
    const statusMap: any = {
      pending: "en attente d’achat",
      completed: "Achat effectué",
      cancelled: "Achat annulé",
      validated: "Achat validé",
    };
  
    return statusMap[status] || "Statut inconnu";
  }
  
  export function getStatus(status: string) {
    const statusMap: any = {
      pending: { label: "en attente d’achat", value: "pending" },
      completed: { label: "Achat effectué", value: "completed" },
      cancelled: { label: "Achat annulé", value: "cancelled" },
      validated: { label: "Achat validé", value: "validated" },
    };
  
    return statusMap[status] || { label: "Unknown", value: "" };
  }
  
  export function otherStatus(status: any) {
    const statusMap: any = {
      pending: "en attente d’achat",
      completed: "Achat effectué",
      cancelled: "Achat annulé",
      validated: "Achat validé",
    };
  
    const otherStatuses: any = [];
  
    for (const key in statusMap) {
      if (key !== status) {
        otherStatuses.push({ label: statusMap[key], value: key });
      }
    }
    console.log("otherStatuses", otherStatuses);
  
    return otherStatuses;
  }
  
  export function bgColorStatus(status: string) {
    const statusMap: any = {
      pending: "#FFE6A6",
      completed: "#DBF2DA",
      cancelled: "#FFCDCD",
      validated : "#159B7B"
    };
  
    return statusMap[status] || "#fff";
  }
  
  
  export   const sideBarContent = [
    { title: "Acceuil", value: "", route: "home", icon: "homeIcon" },
    {
      title: "Alimentation",
      value: "",
      route: "alimentation",
      icon: "alimentationIcon",
    },
    {
      title: "Bons de commande",
      value: "",
      route: "purchaceOrder",
      icon: "purchaceIcon",
    },
    { title: "Produits", value: "", route: "", icon: "productsIcon" },
    { title: "Magasins", value: "", route: "stores", icon: "storeIcon" },
    { title: "Historique", value: "", route: "history", icon: "history" },
    {
      title: "Fournisseurs",
      value: "",
      route: "providers",
      icon: "providerIcon",
    },
  ];


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  
 export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
  
    useLayoutEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowDimensions;
  }

  export function calculateTotalPrice(products : any): number {
    return products.reduce((total : any, product : any) => {
      return total + product?.cost * product?.quantity;
    }, 0);
  }
  export function calculateTotalPriceServices(services : any): number {
    return services.reduce((total : any, service : any) => {
      return total + service?.price * 1;
    }, 0);
  }

  export const totalProduct = (product : any) => {
    return product?.cost * product?.quantity

  }
  export const countryCodeMap : any = {
    AF: "93", // Afghanistan
    AL: "355", // Albania
    DZ: "213", // Algeria
    AS: "1-684", // American Samoa
    AD: "376", // Andorra
    AO: "244", // Angola
    AI: "1-264", // Anguilla
    AQ: "672", // Antarctica
    AG: "1-268", // Antigua and Barbuda
    AR: "54", // Argentina
    AM: "374", // Armenia
    AW: "297", // Aruba
    AU: "61", // Australia
    AT: "43", // Austria
    AZ: "994", // Azerbaijan
    BS: "1-242", // Bahamas
    BH: "973", // Bahrain
    BD: "880", // Bangladesh
    BB: "1-246", // Barbados
    BY: "375", // Belarus
    BE: "32", // Belgium
    BZ: "501", // Belize
    BJ: "229", // Benin
    BM: "1-441", // Bermuda
    BT: "975", // Bhutan
    BO: "591", // Bolivia
    BA: "387", // Bosnia and Herzegovina
    BW: "267", // Botswana
    BV: "47", // Bouvet Island
    BR: "55", // Brazil
    IO: "246", // British Indian Ocean Territory
    BN: "673", // Brunei
    BG: "359", // Bulgaria
    BF: "226", // Burkina Faso
    BI: "257", // Burundi
    CV: "238", // Cabo Verde
    KH: "855", // Cambodia
    CM: "237", // Cameroon
    CA: "1", // Canada
    KY: "1-345", // Cayman Islands
    CF: "236", // Central African Republic
    TD: "235", // Chad
    CL: "56", // Chile
    CN: "86", // China
    CX: "61", // Christmas Island
    CC: "61", // Cocos (Keeling) Islands
    CO: "57", // Colombia
    KM: "269", // Comoros
    CD: "243", // Congo (Democratic Republic)
    CG: "242", // Congo (Republic)
    CK: "682", // Cook Islands
    CR: "506", // Costa Rica
    HR: "385", // Croatia
    CU: "53", // Cuba
    CW: "599", // Curacao
    CY: "357", // Cyprus
    CZ: "420", // Czech Republic
    DK: "45", // Denmark
    DJ: "253", // Djibouti
    DM: "1-767", // Dominica
    DO: "1-809", // Dominican Republic
    EC: "593", // Ecuador
    EG: "20", // Egypt
    SV: "503", // El Salvador
    GQ: "240", // Equatorial Guinea
    ER: "291", // Eritrea
    EE: "372", // Estonia
    SZ: "268", // Eswatini
    ET: "251", // Ethiopia
    FK: "500", // Falkland Islands
    FO: "298", // Faroe Islands
    FJ: "679", // Fiji
    FI: "358", // Finland
    FR: "33", // France
    GF: "594", // French Guiana
    PF: "689", // French Polynesia
    GA: "241", // Gabon
    GM: "220", // Gambia
    GE: "995", // Georgia
    DE: "49", // Germany
    GH: "233", // Ghana
    GI: "350", // Gibraltar
    GR: "30", // Greece
    GL: "299", // Greenland
    GD: "1-473", // Grenada
    GP: "590", // Guadeloupe
    GU: "1-671", // Guam
    GT: "502", // Guatemala
    GG: "44-1481", // Guernsey
    GN: "224", // Guinea
    GW: "245", // Guinea-Bissau
    GY: "592", // Guyana
    HT: "509", // Haiti
    HM: "672", // Heard Island and McDonald Islands
    VA: "39-06", // Holy See
    HN: "504", // Honduras
    HK: "852", // Hong Kong
    HU: "36", // Hungary
    IS: "354", // Iceland
    IN: "91", // India
    ID: "62", // Indonesia
    IR: "98", // Iran
    IQ: "964", // Iraq
    IE: "353", // Ireland
    IM: "44-1624", // Isle of Man
    IL: "972", // Israel
    IT: "39", // Italy
    JM: "1-876", // Jamaica
    JP: "81", // Japan
    JE: "44-1534", // Jersey
    JO: "962", // Jordan
    KZ: "7", // Kazakhstan
    KE: "254", // Kenya
    KI: "686", // Kiribati
    KP: "850", // Korea (North)
    KR: "82", // Korea (South)
    KW: "965", // Kuwait
    KG: "996", // Kyrgyzstan
    LA: "856", // Laos
    LV: "371", // Latvia
    LB: "961", // Lebanon
    LS: "266", // Lesotho
    LR: "231", // Liberia
    LY: "218", // Libya
    LI: "423", // Liechtenstein
    LT: "370", // Lithuania
    LU: "352", // Luxembourg
    MO: "853", // Macau
    MG: "261", // Madagascar
    MW: "265", // Malawi
    MY: "60", // Malaysia
    MV: "960", // Maldives
    ML: "223", // Mali
    MT: "356", // Malta
    MH: "692", // Marshall Islands
    MQ: "596", // Martinique
    MR: "222", // Mauritania
    MU: "230", // Mauritius
    YT: "262", // Mayotte
    MX: "52", // Mexico
    FM: "691", // Micronesia
    MD: "373", // Moldova
    MC: "377", // Monaco
    MN: "976", // Mongolia
    ME: "382", // Montenegro
    MS: "1-664", // Montserrat
    MA: "212", // Morocco
    MZ: "258", // Mozambique
    MM: "95", // Myanmar
    NA: "264", // Namibia
    NR: "674", // Nauru
    NP: "977", // Nepal
    NL: "31", // Netherlands
    NC: "687", // New Caledonia
    NZ: "64", // New Zealand
    NI: "505", // Nicaragua
    NE: "227", // Niger
    NG: "234", // Nigeria
    NU: "683", // Niue
    NF: "672", // Norfolk Island
    MP: "1-670", // Northern Mariana Islands
    NO: "47", // Norway
    OM: "968", // Oman
    PK: "92", // Pakistan
    PW: "680", // Palau
    PS: "970", // Palestine
    PA: "507", // Panama
    PG: "675", // Papua New Guinea
    PY: "595", // Paraguay
    PE: "51", // Peru
    PH: "63", // Philippines
    PN: "64", // Pitcairn
    PL: "48", // Poland
    PT: "351", // Portugal
    PR: "1-787", // Puerto Rico
    QA: "974", // Qatar
    RE: "262", // Reunion
    RO: "40", // Romania
    RU: "7", // Russia
    RW: "250", // Rwanda
    BL: "590", // Saint Barthelemy
    SH: "290", // Saint Helena
    KN: "1-869", // Saint Kitts and Nevis
    LC: "1-758", // Saint Lucia
    PM: "508", // Saint Pierre and Miquelon
    VC: "1-784", // Saint Vincent and the Grenadines
    WS: "685", // Samoa
    SM: "378", // San Marino
    ST: "239", // Sao Tome and Principe
    SA: "966", // Saudi Arabia
    SN: "221", // Senegal
    RS: "381", // Serbia
    SC: "248", // Seychelles
    SL: "232", // Sierra Leone
    SG: "65", // Singapore
    SX: "1-721", // Sint Maarten
    SK: "421", // Slovakia
    SI: "386", // Slovenia
    SB: "677", // Solomon Islands
    SO: "252", // Somalia
    ZA: "27", // South Africa
    GS: "500", // South Georgia and the South Sandwich Islands
    SS: "211", // South Sudan
    ES: "34", // Spain
    LK: "94", // Sri Lanka
    SD: "249", // Sudan
    SR: "597", // Suriname
    SJ: "47", // Svalbard and Jan Mayen
    SE: "46", // Sweden
    CH: "41", // Switzerland
    SY: "963", // Syria
    TW: "886", // Taiwan
    TJ: "992", // Tajikistan
    TZ: "255", // Tanzania
    TH: "66", // Thailand
    TL: "670", // Timor-Leste
    TG: "228", // Togo
    TK: "690", // Tokelau
    TO: "676", // Tonga
    TT: "1-868", // Trinidad and Tobago
    TN: "216", // Tunisia
    TR: "90", // Turkey
    TM: "993", // Turkmenistan
    TC: "1-649", // Turks and Caicos Islands
    TV: "688", // Tuvalu
    UG: "256", // Uganda
    UA: "380", // Ukraine
    AE: "971", // United Arab Emirates
    GB: "44", // United Kingdom
    US: "1", // United States
    UY: "598", // Uruguay
    UZ: "998", // Uzbekistan
    VU: "678", // Vanuatu
    VE: "58", // Venezuela
    VN: "84", // Vietnam
    WF: "681", // Wallis and Futuna
    EH: "212", // Western Sahara
    YE: "967", // Yemen
    ZM: "260", // Zambia
    ZW: "263", // Zimbabwe
  };
  