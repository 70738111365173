// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-hight-container {
  background-color: #f9f9f9;
  height: 90vh;
  overflow-x: auto;
  width: 100vw;
}
.payment-hight-container .title-content .title-txt {
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .payment-hight-container {
    background-color: #f9f9f9;
    height: 90vh;
    overflow-x: auto;
    width: 80vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/ProductPayment/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;AACF;AAEI;EACE,eAAA;EACA,gBAAA;AAAN;;AAKA;EACE;IACE,yBAAA;IACA,YAAA;IACA,gBAAA;IACA,WAAA;EAFF;AACF","sourcesContent":[".payment-hight-container {\n  background-color: #f9f9f9;\n  height: 90vh;\n  overflow-x: auto;\n  width: 100vw;\n\n  .title-content {\n    .title-txt {\n      font-size: 18px;\n      font-weight: 500;\n    }\n  }\n}\n\n@media (min-width: 768px) {\n  .payment-hight-container {\n    background-color: #f9f9f9;\n    height: 90vh;\n    overflow-x: auto;\n    width: 80vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
