import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useLocation, useParams } from "react-router-dom";
import "./index.scss";
import { toast } from "react-toastify";
import getPurchaceById from "../../services/get-puchace-by-id";
import { useWindowDimensions } from "../../utils";
import "./index.scss";

import ProductCard from "../../components/ProductCard";
import ListProductAchatTable from "../../components/ListProductAchatTable";
import getPurchaceServiceById from "../../services/get-purchase-service-by-id";

function DetailsScreen({ route }: any) {
  const location = useLocation();
  const { order, isServices } = location.state;
  const { id } = useParams();
  const [listProduct, setListProduct] = useState<any>([]);
  const { width } = useWindowDimensions();

  const doGetPurchaceServicesById = () => {
    getPurchaceServiceById(id)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          const updatedProducts = response?.data?.content?.services.map(
            (prd: any, index: any) => {
              if (index === response?.data?.content?.services.length - 1) {
                return { ...prd, isOpen: false }; // Last product isValid is false
              }
              return { ...prd, isOpen: false }; // Other products isValid is true
            }
          );
          setListProduct(updatedProducts);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      });
  };

  const doGetPurchaceById = () => {
    getPurchaceById(id)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListProduct(response?.data?.content?.products);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      });
  };

  useEffect(() => {
    if (isServices) {
      doGetPurchaceServicesById();
    } else {
      doGetPurchaceById();
    }
  }, []);

  return (
    <div>
      <Layout route={"detail"}>
        <div className="title-content">
          <span className="title-txt-details">
            {order?.name} /<span style={{ fontWeight: 700 }}>Detail</span>{" "}
          </span>
        </div>
        {width > 768 ? (
          <>
            <ListProductAchatTable
              productList={listProduct}
              order={order}
              isServices={isServices}
            />
          </>
        ) : (
          <div className="list-order-phone-display">
            {listProduct?.length > 0 ? (
              listProduct?.map((prod: any, index: any) => (
                <ProductCard
                  key={index}
                  prod={prod}
                  order={order}
                  isServices={isServices}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        )}
      </Layout>
    </div>
  );
}

export default DetailsScreen;
