import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedTestProps {
  children: JSX.Element;
}

const ProtectedTest: React.FC<ProtectedTestProps> = ({ children }) => {
  const token = localStorage.getItem("token");

  // If there's no token, redirect to login screen
  if (token) {
    return <Navigate to="/home" replace />;
  }

  // If there's a token, render the protected route
  return children;
};

export default ProtectedTest;
