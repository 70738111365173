import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = localStorage.getItem("token");

  // If there's no token, redirect to login screen
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // If there's a token, render the protected route
  return children;
};

export default ProtectedRoute;
