// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mi-container {
  height: 100%;
  width: 100%;
  background-color: cadetblue;
}
.mi-container .mi-header {
  display: flex;
  height: 10%;
  justify-content: flex-end;
}
.mi-container .mi-body {
  height: 90%;
  width: 100%;
  background-color: cornflowerblue;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalInvoice/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,2BAAA;AACF;AAAE;EACE,aAAA;EACA,WAAA;EACA,yBAAA;AAEJ;AAAE;EACE,WAAA;EACA,WAAA;EACA,gCAAA;AAEJ","sourcesContent":[".mi-container {\n  height: 100%;\n  width: 100%;\n  background-color: cadetblue;\n  .mi-header {\n    display: flex;\n    height: 10%;\n    justify-content: flex-end;\n  }\n  .mi-body {\n    height: 90%;\n    width: 100%;\n    background-color: cornflowerblue;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
