import { useState } from "react";
import "./index.scss";
import Modal from "react-modal";
import ModalPayment from "../Modals/ModalPayment";
import { totalProduct } from "../../utils";
import { toast } from "react-toastify";
import payPurchaseOrder from "../../services/payPurchaseOrder";
import payServiceOrder from "../../services/payServiceOrder";
const customStylesModal = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "25vh",
    minHeight: "25vh",
    width: "30vw",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
function ProductPaymentView({ item, order, isServices }: any) {
  const [modalIsOpen, setModalIsOpen] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [isPaid, setIsPaid] = useState(
    isServices
      ? order?.services?.find(
          (service: any) => service?.serviceId?._id === item?._id
        )?.isPaid ?? false
      : item?.isPaid ?? false
  );

  async function handlePayServiceOrService() {
    try {
      setLoading(true);

      const vaultPhoneNumber = localStorage?.getItem("phoneNumber") ?? "";
      if (!vaultPhoneNumber && typeof vaultPhoneNumber !== "string") {
        throw new Error("Error payment : phone number not found !");
      }

      const { data } = isServices
        ? await payServiceOrder({
            body: {
              purchaseServiceId: order._id,
              serviceId: item?._id,
              vaultPhoneNumber,
            },
          })
        : await payPurchaseOrder({
            body: {
              purchaseOrderId: order?._id,
              productId: item._id,
              vaultPhoneNumber,
            },
          });

      if (!data?.success) {
        throw new Error("Error payment  : " + data?.message);
      }

      toast.success("Payment succes ! ");

      setLoading(false);
      setModalIsOpen(false);
      setIsPaid(true);
    } catch (error: any) {
      setLoading(false);
      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error sending SMS "
      );
    }
  }

  return (
    <>
      <div className="payment-view-high-content">
        <div className="payment-wrapper">
          <span className="title-payment-txt">{order?.name}</span>
          <div className="provider-wrapper">
            <span>Fournissuer</span>
            {isServices ? (
              <span>
                {item?.benefactor?.name ? item?.benefactor?.name : "Not setted"}
              </span>
            ) : (
              <span>
                {item?.supplier ? item?.supplier?.fullname : "Not setted"}
              </span>
            )}
          </div>
          {isServices ? (
            <div className="prod-name-wrapper">
              <span>Description</span>
              <span>{item?.description}</span>
            </div>
          ) : (
            <div className="prod-name-wrapper">
              <span>Item</span>
              <span>{item?.name}</span>
            </div>
          )}

          {isServices ? (
            <div className="price-wrapper">
              <span>PT</span>
              <span>{item?.price} dt</span>
            </div>
          ) : (
            <div className="price-wrapper">
              <span>PU</span>
              <span>{item?.cost} dt</span>
            </div>
          )}
          {isServices ? (
            <></>
          ) : (
            <div className="qte-wrapper">
              <span>Quantité</span>
              <span>X {item?.quantity}</span>
            </div>
          )}
          <div className="phone-wrapper">
            <span>Tél</span>
            {isServices ? (
              <span>
                {item?.benefactor?.phone
                  ? item?.benefactor?.phone
                  : "Not setted"}
              </span>
            ) : (
              <span>
                {item?.supplier ? item?.supplier?.fullname : "Not setted"}
              </span>
            )}
          </div>

          <div className="separator" />
          <div className="total-pay-wrapper">
            <span>Montant à payer </span>

            <span>{isServices ? item?.price : totalProduct(item)} dt</span>
          </div>
          <div className="btn--pay-wrapper">
            {
              <button
                className="btn-payer"
                onClick={() => setModalIsOpen(true)}
                disabled={isPaid}
              >
                {isPaid ? "Déjà payer" : "Payer"}
              </button>
            }
          </div>
        </div>
        <div className="vertical-separator" />
        <div className="payment-invoice-wrapper"></div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalPayment
          closeModal={closeModal}
          loading={loading}
          handlePayServiceOrService={handlePayServiceOrService}
        />
      </Modal>
    </>
  );
}

export default ProductPaymentView;
