import { AxiosError } from "axios";
import { postRequest, postRequestLb4 } from "../utils/axios";

type RequestBody = {
  vaultPhoneNumber: string;
  purchaseServiceId: string;
  serviceId: string;
};

type payServiceOrderParams = {
  body: RequestBody;
};

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function payServiceOrder(
  args: payServiceOrderParams
): Promise<DataFromAxiosType> {
  try {
    const { body } = args || {};

    if (body === null || body === undefined) {
      throw new Error("Missing Args in service");
    }

    const { data } = await postRequest("/expense/payExpense", body);

    if (!data?.success) {
      throw new Error("Error payServiceOrder" + data?.message);
    }

    return { data };
  } catch (error: unknown) {
    console.error("Error payServiceOrder:", error);

    let errorMessage = "Unknown error";
    let errorStatus: number | undefined;

    // Check if the error is an AxiosError
    if (error instanceof AxiosError) {
      errorMessage = error?.response?.data?.message || error?.message;
      errorStatus = error?.response?.status;
    } else if (error instanceof Error) {
      errorMessage = error?.message;
    }

    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: errorMessage,
        status: errorStatus || 500,
        content: [],
      },
    };

    return returnedData;
  }
}

export default payServiceOrder;
