import React, { useState } from "react";
import "./index.scss";
import EyeIcon from "./../../assets/eyeIcon.svg";
import Modal from "react-modal";
import ModalPayment from "../Modals/ModalPayment";
import ModalInvoice from "../ModalInvoice";
import { totalProduct, useWindowDimensions } from "../../utils";
const customStylesModal = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "60vh",
    minHeight: "60vh",
    width: "40vw",
    minWidth: "350px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
function CardPaymentProduct({ item, order, isServices }: any) {
  console.log("item", item);
  const [modalIsOpen, setModalIsOpen] = useState<any>(false);
  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);
  const { width } = useWindowDimensions();
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closeModalInvoice = () => {
    setModalInvoiceOpen(false);
  };

  console.log("CardPaymentProduct");

  return (
    <>
      <div className="card-payment-wrapper">
        <span className="title-card-payment-txt">{order?.name}</span>
        <div className="provider-wrapper">
          <span>Fournissuer</span>
          {isServices ? (
            <span>
              {item?.benefactor?.name ? item?.benefactor?.name : "Not setted"}
            </span>
          ) : (
            <span>
              {item?.supplier ? item?.supplier?.fullname : "Not setted"}
            </span>
          )}
        </div>
        {isServices ? (
          <div className="prod-name-wrapper">
            <span>Description</span>
            <span>{item?.description}</span>
          </div>
        ) : (
          <div className="prod-name-wrapper">
            <span>Item</span>
            <span>{item?.name}</span>
          </div>
        )}

        {isServices ? (
          <div className="price-wrapper">
            <span>PT</span>
            <span>{item?.price} dt</span>
          </div>
        ) : (
          <div className="price-wrapper">
            <span>PU</span>
            <span>{item?.cost} dt</span>
          </div>
        )}
        {isServices ? (
          <></>
        ) : (
          <div className="qte-wrapper">
            <span>Quantité</span>
            <span>X {item?.quantity}</span>
          </div>
        )}
        <div className="phone-wrapper">
          <span>Tél</span>
          {isServices ? (
            <span>
              {item?.benefactor?.phone ? item?.benefactor?.phone : "Not setted"}
            </span>
          ) : (
            <span>
              {item?.supplier ? item?.supplier?.fullname : "Not setted"}
            </span>
          )}
        </div>

        <div
          className="invoice-wrapper"
          onClick={() => setModalInvoiceOpen(true)}
        >
          <img src={EyeIcon} alt="eye icon" />
          <span className="txt-incoice">View invoice</span>
        </div>

        <div className="separator" />
        <div className="total-pay-wrapper">
          <span>Montant à payer </span>

          <span>{isServices ? item?.price : totalProduct(item)} dt</span>
        </div>
        <div className="btn--pay-wrapper">
          <button className="btn-payer-cach">Cach Payement </button>

          <button className="btn-payer" onClick={() => setModalIsOpen(true)}>
            Online Transaction
          </button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalPayment closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={modalInvoiceOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalInvoice closeModal={closeModalInvoice} />
      </Modal>
    </>
  );
}

export default CardPaymentProduct;
