import React from "react";
import "./index.scss";
import IconAchat from "./../../assets/iconAchat.svg";
import IconService from "./../../assets/iconService.svg";
import { useNavigate } from "react-router-dom";

const sideBarList = [
  { name: "Achat", value: "achat", navigation: "/home" },
  { name: "Services", value: "services", navigation: "/services" },
];
function SideBar({ route }: any) {
  const navigate = useNavigate();
  return (
    <div className="sideBar-wrapper">
      {sideBarList?.length > 0 ? (
        sideBarList?.map((item: any, index: any) => (
          <button
            key={index}
            className={
              route === item?.value
                ? "btn-in-side-bar-clicked"
                : "btn-in-side-bar"
            }
            onClick={() => navigate(item?.navigation)}
          >
            <div className="icon-achat-wrapper">
              <img
                src={item?.value === "achat" ? IconAchat : IconService}
                alt="icon achat"
              />
            </div>
            {item?.name}
          </button>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default SideBar;
