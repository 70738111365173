import React, { useEffect, useState } from "react";
import "./index.scss";
import Layout from "../../components/Layout";
import ListOrders from "../../components/ListOrders";
import getAllPurchaces from "../../services/get-list-purchace";
import { toast } from "react-toastify";
import updateOrderStatus from "../../services/update-purchace-status";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
function HomeScreen() {
  const [orderList, setOrderList] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);

  const doGetAllPurchaces = (limit: any, skip: any) => {
    setLoading(true);
    if (limit !== undefined && skip !== undefined) {
      setLoading(true);
      getAllPurchaces(limit, skip)
        .then((response) => {
          setLoading(false);

          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
          if (response?.data?.success) {
            setOrderList([
              ...orderList,
              ...response?.data?.content?.purchaseOrders,
            ]);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const doGetInitPurchaces = () => {
    setLoading(true);
    getAllPurchaces(10, 0)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setOrderList(response?.data?.content?.purchaseOrders);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const doUpdateStatus = (data: any, id: any) => {
    updateOrderStatus(data, id)
      .then((response: any) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          doGetInitPurchaces();
          setSkip(0);
          setLimit(10);
        }
      })
      .catch((error: any) => {
        toast?.error(error);
      });
  };
  const loadMore = () => {
    let newSkip = skip + limit;
    doGetAllPurchaces(limit, newSkip);
    setSkip(newSkip);
  };

  useEffect(() => {
    doGetInitPurchaces();
  }, []);
  return (
    <div>
      <Layout route={"achat"}>
        <div className="title-content">
          <span className="title-txt">Bon de commandes </span>
        </div>
        {loading ? (
          <div className="loading-content">
            <Lottie
              options={defaultOptionsLoading}
              height={"40%"}
              width={"18%"}
            />
          </div>
        ) : (
          <div>
            <ListOrders
              loadMore={loadMore}
              orderList={orderList}
              doUpdateStatus={doUpdateStatus}
            />
          </div>
        )}
      </Layout>
    </div>
  );
}

export default HomeScreen;
