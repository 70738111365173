// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.layout-container .layout-side-bar {
  height: 100vh;
  width: 20vw;
}
.layout-container .layout-side-bar .logo-container {
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.layout-container .layout-side-bar .logo-container .icon-style {
  height: 50%;
  width: 40%;
}
.layout-container .layout-side-bar .logo-container .logo-txt-wrapper {
  display: flex;
  flex-direction: column;
}
.layout-container .layout-body-content {
  width: 80vw;
  transition: filter 0.3s ease;
  height: 100vh;
  background-color: #f9f9f9;
}
.layout-container .layout-body-content.blurred {
  filter: blur(5px);
}
@media (max-width: 768px) {
  .layout-container {
    flex-direction: column;
  }
  .layout-container .layout-header {
    height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }
  .layout-container .layout-header .btn-logo,
  .layout-container .layout-header .btn-icon {
    margin: 10px;
    background-color: #fff;
    border: none;
  }
  .layout-container .layout-body-content {
    width: 100vw;
    height: 100vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;AACJ;AAAI;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EAEA,mBAAA;AACN;AAAM;EACE,WAAA;EACA,UAAA;AAER;AAAM;EACE,aAAA;EACA,sBAAA;AAER;AAGE;EACE,WAAA;EACA,4BAAA;EACA,aAAA;EACA,yBAAA;AADJ;AAGI;EACE,iBAAA;AADN;AAKE;EArCF;IAsCI,sBAAA;EAFF;EAIE;IACE,YAAA;IACA,aAAA;IACA,8BAAA;IACA,mBAAA;IACA,sBAAA;EAFJ;EAII;;IAEE,YAAA;IACA,sBAAA;IACA,YAAA;EAFN;EAME;IACE,YAAA;IACA,aAAA;EAJJ;AACF","sourcesContent":[".layout-container {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n\n  .layout-side-bar {\n    height: 100vh;\n    width: 20vw;\n    .logo-container {\n      height: 20%;\n      display: flex;\n      flex-direction: row;\n\n      align-items: center;\n      .icon-style {\n        height: 50%;\n        width: 40%;\n      }\n      .logo-txt-wrapper {\n        display: flex;\n        flex-direction: column;\n      }\n    }\n  }\n\n  .layout-body-content {\n    width: 80vw;\n    transition: filter 0.3s ease; // Smooth transition for blur\n    height: 100vh;\n    background-color: #f9f9f9;\n\n    &.blurred {\n      filter: blur(5px); // Adjust the blur intensity as needed\n    }\n  }\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n\n    .layout-header {\n      height: 15vh;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      background-color: #fff;\n\n      .btn-logo,\n      .btn-icon {\n        margin: 10px;\n        background-color: #fff;\n        border: none;\n      }\n    }\n\n    .layout-body-content {\n      width: 100vw;\n      height: 100vh;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
