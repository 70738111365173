import React from "react";
import "./index.scss";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";
function ModalPayment({ closeModal, loading, handlePayServiceOrService }: any) {
  return (
    <div className="modal-payment-container">
      <div className="mpc-header">
        <span className="mpc-title-txt">Attention !</span>
      </div>
      <div className="mpc-horiz-separ" />
      <div className="mpc-body">
        <span className="mpc-body-txt">
          Vous êtes sur le point de procéder au paiement en ligne.
        </span>
        <span className="mpc-body-txt">Souhaitez-vous confirmer ?</span>
      </div>
      {loading ? (
        <Lottie options={defaultOptionsLoading} height={"20%"} width={"15%"} />
      ) : (
        <div className="mpc-footer">
          <button className="mpc-btn-cancel" onClick={() => closeModal()}>
            Annuler
          </button>
          <button
            className="mpc-btn-confirm"
            onClick={handlePayServiceOrService}
          >
            Confirmer
          </button>
        </div>
      )}
    </div>
  );
}

export default ModalPayment;
