import React, { useState } from "react";
import "./index.scss";
import { useWindowDimensions } from "../../utils";
import IconExpense from "./../../assets/IconExpense.svg";
import Menu from "../Menu";
import SideBar from "../SideBar";
import Header from "../Header";

function Layout({ children, route }: any) {
  const {  width } = useWindowDimensions();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="layout-container">
      {width > 768 ? (
        <div className="layout-side-bar">
          <div className="logo-container">
            <img src={IconExpense} alt="Icon Expense" className="icon-style" />
            <div className="logo-txt-wrapper">
              <span style={{ fontWeight: 700, fontSize: 28 }}>Pizzaïe </span>
              <span style={{ fontWeight: 700, fontSize: 28 }}>Expense</span>
            </div>
          </div>
          <SideBar route={route} />
        </div>
      ) : null}

      {width <= 768 && (
        <>
          <Header toggleMenu={toggleMenu} />
          <Menu
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            toggleMenu={toggleMenu}
          />
        </>
      )}

      <div className={`layout-body-content ${showMenu ? "blurred" : ""}`}>
        {children}
      </div>
    </div>
  );
}

export default Layout;
