import React, { useState } from "react";

import Select, { StylesConfig } from "react-select";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import "./index.scss";
import { defaultOptionsEmpty } from "../../assets/lottie-animations/animationOptions";
import Modal from "react-modal";

import {
  capitalizeFirstLetter,
  formatDateAndTime,
  getStatus,
  useWindowDimensions,
} from "../../utils";
import InvoiceModal from "../Modals/InvoiceModal";
import OrderCard from "../OrderCard";
const dot = (color = "transparent"): any => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});
const circleStyles: any = {
  pending: { color: "#FFE6A6" },
  completed: { color: "#DBF2DA" },
  cancelled: { color: "#FFCDCD" },
};

const styleInput = (status: any) => {
  console.log("fff", status);

  let colourStyles = {};
  return (colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#ddd"
          : undefined,
        color: isDisabled ? "#ccc" : "#000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "#fd44"
            : undefined,
        },
      };
    },
    input: (styles: any) => ({ ...styles, ...dot() }),
    placeholder: (styles: any) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      ...dot(circleStyles[status]?.color),
    }),
  });
};
const customStylesModal = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "50vh",
    minHeight: "50vh",
    width: "33%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
function ListOrders({
  loadMore,
  orderList,
  doUpdateStatus = () => {},
  isServices,
}: any) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [seletedOrder, setSelectedOrder] = useState<any>(null);
  const {  width } = useWindowDimensions();

  const navigate = useNavigate();
  const statuses = [
    { label: "En attente d’achat", value: "pending" },
    { label: "Achat effectué", value: "completed" },
    { label: "Achat annulé", value: "cancelled" },
    { label: "Achat validé", value: "validated" },
  ];

  const handleOptionSelect = (option: any, order: any) => {
    const bodyData = {
      status: option?.value,
    };
    console.log("optionsss", option, order, order?._id, bodyData);
    doUpdateStatus(bodyData, order?._id);
  };

  const doOpenModal = (order: any) => {
    console.log("ioder", order);

    setSelectedOrder(order);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      {width > 768 ? (
        <div className="products-container-po">
          {orderList?.length > 0 ? (
            <table className="table-style">
              <thead style={{ height: 60 }}>
                <tr>
                  <th className="title-txt">Item</th>
                  <th className="title-txt">Date</th>
                  <th className="title-txt">Quantité</th>
                  <th className="title-txt"></th>
                  <th className="title-txt">Etat</th>
                </tr>
              </thead>
              <tbody>
                {orderList?.length > 0 ? (
                  orderList.map((order: any, index: any) => (
                    <tr key={index} className="line-table-style">
                      <td
                        className="td-right"
                        onClick={() =>
                          navigate(`/details/${order?._id}`, {
                            state: {order : order , isServices : isServices},
                          })
                        }
                      >
                        <span className="td-right-text-style">
                          {Boolean(order?.name)
                            ? capitalizeFirstLetter(order?.name)
                            : "Not set yet"}
                        </span>
                      </td>
                      <td className="td-center">
                        <span className="td-center-txt">
                          {Boolean(order?.date)
                            ? formatDateAndTime(order?.date)
                            : "Not set yet"}
                        </span>
                      </td>
                      <td className="td-center-type">
                        <span className="td-center-txt">
                          {order?.products?.length} articles
                        </span>
                      </td>
                      <td className="td-right-status">
                        <i
                          className="bx bx-images"
                          style={{ fontSize: 26 }}
                          onClick={() => doOpenModal(order)}
                        ></i>
                      </td>
                      <td className="td-right-status">
                        <Select
                          classNamePrefix="select"
                          options={[...statuses, { id: order?._id }]}
                          value={getStatus(order?.status)}
                          styles={styleInput(order?.status)}
                          onChange={(option) =>
                            handleOptionSelect(option, order)
                          }
                        />
                      </td>
                    </tr>
                  )).reverse()
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          ) : (
            <div className="empty-list-purchace">
              <Lottie
                options={defaultOptionsEmpty}
                height={"60%"}
                width={"30%"}
              />
            </div>
          )}

          <button className="btn-load-more" onClick={() => loadMore()}>
            load more <i className="bx bx-loader-alt"></i>
          </button>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStylesModal}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <InvoiceModal purchace={seletedOrder} />
          </Modal>
        </div>
      ) : (
        <div className="list-order-phone-display">
          {orderList?.length > 0 ? (
            <div>
              {orderList?.map((order: any) => (
                <OrderCard order={order} isServices={isServices} />
              ))}
              <div className="load-more-content">
                <button className="btn-load-more" onClick={loadMore}>
                  load more
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}

export default ListOrders;
