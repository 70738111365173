import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils";
import Lottie from "react-lottie";
import { defaultOptionsEmpty } from "../../assets/lottie-animations/animationOptions";

function ListProductAchatTable({ productList, order, isServices }: any) {
  const navigate = useNavigate();
  return (
    <div>
      {productList?.length > 0 ? (
        <table className="table-style">
          <thead
            style={{
              height: 60,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <tr>
              <th className="title-txt" style={{ textAlign: "start" }}>
                Item
              </th>
              <th className="title-txt" style={{ textAlign: "start" }}>
                Price
              </th>
              <th className="title-txt" style={{ textAlign: "start" }}>
                {isServices ? "Descroption" : "Quantité"}
              </th>
            </tr>
          </thead>
          <tbody>
            {productList
              .map((prod: any, index: any) => (
                <tr key={index} className="line-table-style">
                  <td
                    className="td-right"
                    onClick={() =>
                      navigate("/payment", {
                        state: {
                          product: isServices ? prod?.serviceId : prod,
                          order: order,
                          isServices: isServices,
                        },
                      })
                    }
                  >
                    <span className="td-right-text-style">
                      {Boolean(prod?.name)
                        ? capitalizeFirstLetter(prod?.name)
                        : isServices
                        ? prod?.serviceId?.name
                        : "Not set yet"}
                    </span>
                  </td>
                  <td className="td-center">
                    <span className="td-center-txt">
                      {isServices ? prod?.serviceId?.price : prod?.cost} dt
                    </span>
                  </td>
                  <td className="td-center-type">
                    {isServices ? (
                      <span className="td-center-txt">
                        {prod?.serviceId?.description}
                      </span>
                    ) : (
                      <span className="td-center-txt">
                        {isServices ? productList?.length : prod?.quantity}{" "}
                        articles
                      </span>
                    )}
                  </td>
                </tr>
              ))
              .reverse()}
          </tbody>
        </table>
      ) : (
        <div className="empty-list-purchace">
          <Lottie options={defaultOptionsEmpty} height={"60%"} width={"30%"} />
        </div>
      )}
    </div>
  );
}

export default ListProductAchatTable;
