import React, { useState } from "react";
import "./index.scss";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";

import login from "../../services/login";
import "react-phone-number-input/style.css";
import sendOtpSMS from "../../services/sendSmsOtp";
import validateSmsOtp from "../../services/validateSmsOtp";
import verifyAuthenticatorCode from "../../services/verify-authenticator-code";

function ConfirmationScreen() {
  const [loading, setLoading] = useState(false);

  const [smsIsSent, setSmsIsSent] = useState(false);

  const [otpCode, setOtpCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [verifyOtpType, setVerifyOtpType] = useState<"AUTHENTICATOR" | "OTP">(
    "AUTHENTICATOR"
  );

  const navigate = useNavigate();
  const location = useLocation();

  const { countryCode, phoneNumber } = location?.state || {};

  console.log("countryPhone", countryCode);
  console.log("phoneNumber", phoneNumber);

  const doLogin = () => {};

  async function handleSendOtpSms(): Promise<void> {
    try {
      setLoading(true);

      const { data } = await sendOtpSMS({
        body: { countryCode, phoneNumber: "56687357" },
      });

      if (!data?.success) {
        throw new Error("Error sending SMS : " + data?.message);
      }

      setSmsIsSent(true);

      setTimeout(() => {
        setSmsIsSent(false);
      }, 120000);

      toast.success("SMS envoyé avec succés!");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error sending SMS "
      );
    }
  }

  async function handleValidateSmsOtp(): Promise<void> {
    try {
      if (!otpCode && otpCode?.length <= 6) {
        setErrorMessage("OTP not enabled ");
        return;
      }
      setLoading(true);

      const { data } = await validateSmsOtp({
        body: { countryCode, phoneNumber, otp: otpCode },
      });

      if (!data?.success) {
        throw new Error("Error validating  SMS : " + data?.message);
      }

      toast.success("OTP succés !");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error validating SMS "
      );
    }
  }

  async function handleValidateAuthenticatorCode(): Promise<void> {
    try {
      if (!otpCode && otpCode?.length <= 6) {
        setErrorMessage("OTP not enabled ");
        return;
      }
      setLoading(true);

      const { data } = await verifyAuthenticatorCode("56687357", otpCode);

      if (!data?.success) {
        throw new Error(
          "Error validating  authenticator code : " + data?.message
        );
      }

      toast.success("Authenticator code succés !");

      localStorage.setItem("token", data?.content?.token);
      navigate("/home");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error validating  authenticator code : "
      );
    }
  }

  return (
    <div>
      {loading ? (
        <div className="loading-content">
          <Lottie
            options={defaultOptionsLoading}
            height={"40%"}
            width={"18%"}
          />
        </div>
      ) : (
        <div className="background-image-wrapper">
          <div className="form-container">
            <div className="login-logo-wrapper">
              <img
                src="./../../../logoLogin.png"
                className="logo-login"
                alt="logo login"
              />
            </div>
            <p>ZZ Code</p>
            {verifyOtpType === "AUTHENTICATOR" ? (
              <p>
                Veuillez ouvrir votre application Authenticator et entrer le
                code à 6 chiffres pour confirmer votre identité. Si vous n'avez
                pas l'application Authenticator, ouvrez l'application PizzaieHub
                et accédez à l'écran de votre profil pour suivre les étapes
                nécessaires et obtenir le code d'authentification.
              </p>
            ) : (
              <p>
                Un SMS contenant un code à 6 chiffres a été envoyé à votre
                numéro de téléphone. Veuillez vérifier vos messages et entrer le
                code reçu pour confirmer votre identité.
              </p>
            )}
            <div className="input-wrapper">
              <VerificationInput
                autoFocus={true}
                validChars={"0-9"}
                classNames={{
                  container: "container",
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                  characterFilled: "character--filled",
                }}
                onComplete={(props) => {
                  console.log("proops", props);
                  setOtpCode(props);
                }}
              />
              {errorMessage && errorMessage?.length && <p>{errorMessage}</p>}
            </div>
            {verifyOtpType === "OTP" && smsIsSent ? (
              <p>resend disabled</p>
            ) : (
              verifyOtpType === "OTP" &&
              !smsIsSent && (
                <p
                  onClick={() => {
                    handleSendOtpSms();
                  }}
                >
                  resend
                </p>
              )
            )}

            <button
              className="button"
              onClick={() => {
                verifyOtpType === "OTP"
                  ? handleValidateSmsOtp()
                  : handleValidateAuthenticatorCode();
              }}
            >
              Suivant
            </button>
            <p>OR</p>
            <button
              className="button"
              onClick={() => {
                setVerifyOtpType((prev) =>
                  prev === "OTP" ? "AUTHENTICATOR" : "OTP"
                );
                verifyOtpType === "OTP" && handleSendOtpSms();
              }}
            >
              {verifyOtpType === "OTP"
                ? "Authenticator app code"
                : "Send OTP SMS"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfirmationScreen;
