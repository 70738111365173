// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/backgroundExpense.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.background-image-wrapper .form-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 70%;
}
.background-image-wrapper .form-container .login-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-image-wrapper .form-container .login-logo-wrapper .logo-login {
  height: 130%;
  width: 130%;
  object-fit: contain;
}
.background-image-wrapper .form-container .input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 3rem 0rem;
  padding: 0.75rem;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  font-size: 1rem;
}
.background-image-wrapper .form-container .button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/LoginScreen/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,yDAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;AACF;AAAE;EACE,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,2CAAA;EACA,UAAA;AAEJ;AADI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAGN;AAFM;EACE,YAAA;EACA,WAAA;EACA,mBAAA;AAIR;AAAI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;AAEN;AAEI;EACE,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;AAAN","sourcesContent":[".background-image-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  background-image: url(\"../../assets/backgroundExpense.png\");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  .form-container {\n    background: rgba(255, 255, 255, 0.8);\n    padding: 2rem;\n    border-radius: 10px;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n    width: 70%;\n    .login-logo-wrapper {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      .logo-login {\n        height: 130%;\n        width: 130%;\n        object-fit: contain;\n      }\n    }\n\n    .input-wrapper {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: center;\n      height: 100%;\n      margin: 3rem 0rem;\n      padding: 0.75rem;\n      border: 1px solid #ccc;\n      background-color: #fff;\n      border-radius: 5px;\n      font-size: 1rem;\n      .input-phone {\n      }\n    }\n    .button {\n      width: 100%;\n      padding: 0.75rem;\n      background-color: #007bff;\n      color: white;\n      border: none;\n      border-radius: 5px;\n      cursor: pointer;\n      font-size: 1rem;\n      margin-bottom: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
