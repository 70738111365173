import React from "react";
import "./index.scss";
function ModalInvoice({ closeModal }: any) {
  return (
    <div className="mi-container">
      <div className="mi-header">
        <i className="bx bx-x" onClick={() => closeModal()}></i>
      </div>
      <div className="mi-body"></div>
    </div>
  );
}

export default ModalInvoice;
