import React from "react";
import "./index.scss";
import {
  calculateTotalPrice,
  calculateTotalPriceServices,
  formatDateAndTime,
} from "../../utils";
import { useNavigate } from "react-router-dom";
function OrderCard({ order, isServices }: any) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/details/${order?._id}`, { state: {order : order , isServices : isServices }});
  };

  return (
    <div className="order-card" onClick={() => handleNavigate()}>
      <span className="title-order-txt">{order?.name}</span>
      <div className="provider-content">
        <span>Date</span>
        <span>{formatDateAndTime(order?.date)}</span>
      </div>
      <div className="price-content">
        <span>Pt</span>
        {isServices ? (
          <span>{calculateTotalPriceServices(order?.services)}</span>
        ) : (
          <span>{calculateTotalPrice(order?.products)}</span>
        )}
      </div>
      <div className="status-content">
        <span>Etat</span>
        <span>{order?.status}</span>
      </div>
    </div>
  );
}

export default OrderCard;
