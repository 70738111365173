// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-card {
  min-width: 330px;
  min-height: 100px;
  margin: 15px;
  padding: 10px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06);
  padding-top: 15px;
  padding-bottom: 5px;
}
.order-card .title-order-txt {
  color: #1A5F9E;
  font-weight: 700;
}
.order-card .provider-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.order-card .price-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.order-card .status-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.no-orders-message {
  font-size: 18px;
  color: gray;
  text-align: center;
  padding: 20px;
}

@media (min-width: 768px) {
  .list-order-phone-display {
    justify-content: flex-start;
    padding-top: 32px;
  }
  .provider-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .price-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .status-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/OrderCard/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,4EAAA;EACA,iBAAA;EACA,mBAAA;AACF;AAAE;EACE,cAAA;EACA,gBAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;;AAEA;EACE,eAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,2BAAA;IACA,iBAAA;EACF;EACA;IACE,aAAA;IACA,mBAAA;IACA,8BAAA;EACF;EACA;IACE,aAAA;IACA,mBAAA;IACA,8BAAA;EACF;EACA;IACE,aAAA;IACA,mBAAA;IACA,8BAAA;EACF;AACF","sourcesContent":[".order-card {\n  min-width: 330px;\n  min-height: 100px;\n  margin: 15px;\n  padding: 10px;\n  border-radius: 6px;\n  background: #fff;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06); // Enhanced shadow\n  padding-top: 15px;\n  padding-bottom: 5px;\n  .title-order-txt{\n    color: #1A5F9E;\n    font-weight: 700;\n  }\n  .provider-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .price-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .status-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n}\n\n.no-orders-message {\n  font-size: 18px;\n  color: gray;\n  text-align: center;\n  padding: 20px;\n}\n\n@media (min-width: 768px) {\n  .list-order-phone-display {\n    justify-content: flex-start; // Align items at the top for larger screens\n    padding-top: 32px;\n  }\n  .provider-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .price-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .status-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
