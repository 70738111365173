import "./index.scss";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import CardPaymentProduct from "../../components/CardPaymentProduct";
import { useWindowDimensions } from "../../utils";
import ProductPaymentView from "../../components/ProductPaymentView";
function ProductPayment() {
  const location = useLocation();
  console.log("location.state", location.state);
  const { product, order, isServices } = location.state;
  const { width } = useWindowDimensions();

  return (
    <div>
      <Layout route={"payment"}>
        <div className="payment-hight-container">
          <div className="title-content">
            <span className="title-txt">
              <i
                className="bx bx-arrow-back"
                style={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              ></i>{" "}
              {order?.name}/product/Payment
            </span>
          </div>
          <div>
            {width > 768 ? (
              <ProductPaymentView
                item={product}
                order={order}
                isServices={isServices}
              />
            ) : (
              <CardPaymentProduct
                item={product}
                order={order}
                isServices={isServices}
              />
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ProductPayment;
