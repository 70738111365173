import React from "react";
import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "boxicons/css/boxicons.min.css";
import "react-toastify/dist/ReactToastify.css";
import ServicesScreen from "./screens/ServicesScreen";
import DetailsScreen from "./screens/DetailsScreen";
import ProductPayment from "./screens/ProductPayment";
import LoginScreen from "./screens/LoginScreen";
import ConfirmationScreen from "./screens/ConfirmationScreen";
import { ToastContainer } from "react-toastify";
import { Navigate } from "react-router-dom"; // Import Navigate
import ProtectedRoute from "./ProtectedRoute";
import ProtectedTest from "./ProtectedTest";

function App() {
  const token = localStorage.getItem("token");

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        {/* Public Route: Login (Redirect to /home if already authenticated) */}
        <Route
          path="/login"
          element={
            <ProtectedTest>
              <LoginScreen />
            </ProtectedTest>
          }
        />

        {/* Protected Routes */}
        <Route
          path="/confirmation"
          element={
            <ProtectedTest>
              <ConfirmationScreen />
            </ProtectedTest>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <HomeScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/services"
          element={
            <ProtectedRoute>
              <ServicesScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/details/:id"
          element={
            <ProtectedRoute>
              <DetailsScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <ProductPayment />
            </ProtectedRoute>
          }
        />

        {/* Catch-all route for undefined paths, redirect to /home */}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
