import React from "react";
import "./index.scss";
import IconExpense from "./../../assets/IconExpense.svg";
import { useNavigate } from "react-router-dom";

function Header({ toggleMenu }: any) {
  const navigate = useNavigate();

  return (
    <div className="layout-header">
      <button className="btn-logo">
        <img src={IconExpense} alt="Logo" onClick={() => navigate("/home")} />
      </button>
      <button className="btn-icon" onClick={toggleMenu}>
        <i className="bx bx-menu" style={{ fontSize: 32 }}></i>
      </button>
    </div>
  );
}

export default Header;
