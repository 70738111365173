import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
function ProductCard({ prod, order, isServices }: any) {
  console.log("prod", prod);
  const navigate = useNavigate();
  return (
    <div
      className="prod-card"
      onClick={() =>
        navigate("/payment", {
          state: { product: isServices ? prod?.serviceId : prod, order: order, isServices: isServices },
        })
      }
    >
      <span className="title-order-txt">
        {isServices ? prod?.serviceId?.name : prod?.name}
      </span>
      <div className="provider-content">
        <span>{isServices ? "Description" : "Qte"}</span>
        <span>
          {isServices ? prod?.serviceId?.description : prod?.quantity}
        </span>
      </div>
      <div className="price-content">
        <span>PU</span>
        <span>{isServices ? prod?.serviceId?.price : prod?.cost}</span>
      </div>
      <div className="status-content">
        <span>Etat</span>
        <span>{prod?.isValidated ? "validé" : "pas validé"}</span>
      </div>
    </div>
  );
}

export default ProductCard;
