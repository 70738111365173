// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-content {
  padding: 15px;
  display: flex;
  align-items: center;
  height: 10%;
}
.title-content .title-txt {
  font-size: 24px;
  font-weight: 600;
}

.loading-content {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/HomeScreen/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;AAER;;AACA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".title-content{\n    padding: 15px;\n    display: flex;\n    align-items: center;\n    height: 10%;\n    .title-txt{\n        font-size: 24px;\n        font-weight: 600;\n    }\n}\n.loading-content{\n    height: 70vh;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
