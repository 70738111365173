import React, { useEffect, useState } from "react";
import "./index.scss";
import Layout from "../../components/Layout";
import getServicesByPaginationUp from "../../services/get-services";
import { toast } from "react-toastify";
import ListOrders from "../../components/ListOrders";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
function ServicesScreen() {
  const [loading, setLoading] = useState(false);
  const [orderListServices, setOrderListServices] = useState<any>([]);
  const doGetAllPurchacesServices = (limit: any, skip: any) => {
    if (limit !== undefined && skip !== undefined) {
      setLoading(true);
      getServicesByPaginationUp(limit, skip)
        .then((response) => {
          setLoading(false);

          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
          if (response?.data?.success) {
            setOrderListServices([
              ...orderListServices,
              ...response?.data?.content?.services,
            ]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    doGetAllPurchacesServices(20, 0);
  }, []);
  return (
    <div>
      <Layout route={"services"}>
        <div className="title-content">
          <span className="title-txt">Bon de services </span>
        </div>
        <>
          {loading ? (
            <Lottie
              options={defaultOptionsLoading}
              height={"40%"}
              width={"25%"}
            />
          ) : (
            <ListOrders orderList={orderListServices} isServices={true} />
          )}
        </>
      </Layout>
    </div>
  );
}

export default ServicesScreen;
