import React, { useEffect, useState } from "react";
import "./index.scss";
import deleteInvoiceImage from "../../../services/delete-purchace-invoice-image";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";
const convertByteArrayToBlob = (
  byteArray: number[],
  mimeType: string
): Blob => {
  return new Blob([new Uint8Array(byteArray)], { type: mimeType });
};

// Utility function to convert a Blob to a Base64 string
const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
function ImageInvoiceDetail({
  displayDetailInvoice,
  setDisplayDetailInvoice,
  imageToDisplay,
  setImageToDisplay,
  purchace,
  closeDetailImage,
  setImages,
  images,
  doGetPurchaseById,
}: any) {

  const [loading, setLoading] = useState(false);
  const [deleteModeConfirm, setDeleteModeConfirm] = useState(false);

  const [imageSrc, setImageSrc] = useState<any>(null);

  // Convert File to Data URL when component mounts or image changes
  React.useEffect(() => {
    if (imageToDisplay && imageToDisplay.data && !imageToDisplay?.fromDb) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target?.result);
      };
      reader.readAsDataURL(imageToDisplay.data);
    }
  }, [imageToDisplay]);

  const [base64, setBase64] = useState("");
  useEffect(() => {
    if (imageToDisplay?.data?.data && imageToDisplay?.data?.data.length > 0) {
      const blob = convertByteArrayToBlob(
        imageToDisplay?.data?.data,
        "image/png"
      ); // Adjust MIME type as necessary
      convertBlobToBase64(blob)
        .then((base64String) => {
          setBase64(base64String);
        })
        .catch((error) => {
          console.error("Error converting blob to base64:", error);
        });
    }
  }, [imageToDisplay?.data?.data]);
  console.log("base", base64);

  const deleteConfirm = () => {
    setDeleteModeConfirm(true);
  };
  const cancelDeletion = () => {
    setDeleteModeConfirm(false);
  };

  const deleteOneImageInvoice = () => {
    if (!imageToDisplay?.fromDb) {
      const updatedImages = images.filter(
        (image: any) => image._id !== imageToDisplay._id
      );

      setImages((prevImages: any) => updatedImages);
      closeDetailImage();
      return;
    }

    setLoading(true);

    deleteInvoiceImage(purchace?._id, imageToDisplay?._id)
      .then((response) => {
        setLoading(false);
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          closeDetailImage();
          doGetPurchaseById(purchace?._id);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error("error :", error);
      });
  };

  const closeModal = () => {
    setDisplayDetailInvoice(false);
    setImageToDisplay(null);
  };
  console.log("imageToDisplay", imageToDisplay);

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <Lottie
            options={defaultOptionsLoading}
            height={"60%"}
            width={"30%"}
          />
        </div>
      ) : (
        <div className="image-invoice-container">
          <div className="image-invoice-header">
            <i
              className="bx bx-chevron-left"
              style={{ fontSize: 26, flex: deleteModeConfirm ? 1 : 0 }}
              onClick={() => closeModal()}
            ></i>
            <span style={{ flex: 1, textAlign: "center" }}>Facture 12</span>
            {deleteModeConfirm ? (
              <div className="deletion-confirm-container">
                <button className="cancel-btn" onClick={() => cancelDeletion()}>
                  Annuler
                </button>
                <button
                  className="confirm-btn"
                  onClick={() => deleteOneImageInvoice()}
                >
                  confirm
                </button>
              </div>
            ) : (
              <i
                className="bx bx-trash"
                style={{ fontSize: 26, color: "red" }}
                onClick={() => deleteConfirm()}
              ></i>
            )}
          </div>
          <div className="image-invoice-body">
            {base64 && imageToDisplay?.fromDb ? (
              <img
                src={base64}
                alt="Uploaded content"
                className="img-invoice"
              />
            ) : imageSrc && !imageToDisplay?.fromDb ? (
              <img
                src={imageSrc}
                alt="Uploaded content"
                className="img-invoice"
              />
            ) : (
              <p>No image available</p>
            )}
          </div>
          <div className="image-invoice-footer"></div>
        </div>
      )}
    </>
  );
}

export default ImageInvoiceDetail;
