import "./index.scss";
import { useNavigate } from "react-router-dom";
import IconExpense from "./../../assets/IconExpense.svg";
function Menu({ showMenu, setShowMenu, toggleMenu }: any) {
  const navigate = useNavigate();
  return (
    <div className={`menu-wrapper`}>
      <div className={`menu-container ${showMenu ? "open" : ""}`}>
        <div className="logo-container">
          <div className="close-container">
            <i
              className="bx bx-x"
              style={{ fontSize: 26 }}
              onClick={toggleMenu}
            ></i>
          </div>
          <div className="img-container">
            <img src={IconExpense} alt="appIcon" />
          </div>
        </div>
        <ul>
          <li onClick={() => navigate("/services")}>Services</li>
          <li onClick={() => navigate("/home")}>Achats</li>
        </ul>
      </div>
    </div>
  );
}

export default Menu;
